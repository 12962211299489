// Here you can add other styles
//--------------------  card coreUI custom ---------------//
//Button
.btn {
  min-width: 120px;
  min-height: 38px;
  width: auto;
  font: 1vw sans-serif;
}
.btn-gray {
  background-color: $gray_color;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  color: #ffffff;
}
.btn-warning,
.btn-warning:hover,
.btn-warning:active,
.btn-warning:focus,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #ffffff;
}

//----------------------- end -----------------------------//
