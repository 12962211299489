$background_color: #f3f5f7;
//--------- input ----------//
.ant-input {
  border-radius: 0.5rem !important;
  padding: 15px !important;
}
//--------- end ----------//

.fixed-color-label .ant-form-item-label > label {
  color: white;
}

//---------select-selector ----------//
.ant-select-selector {
  border-radius: 0.5rem !important;
  background: $background_color !important;
  border-color: $background_color !important;
  height: 40px !important;
}
//--------- end ----------//
//datepicker & timepicker //
.ant-picker {
  background-color: $background_color !important;
  border-color: $background_color !important;
  border-radius: 5px !important;
}
.ant-picker-suffix {
  color: #bab9bf !important;
}
//--------- end ----------//

//--------- Form ----------//
.ant-form-item {
  // margin-bottom: 10px;
  @extend .mb-3;
}
.ant-input-number {
  @extend .d-flex;
  @extend .align-items-center;
  background-color: $background_color !important;
  border-color: unset !important;
  border: 0px !important;
  border-radius: 10px !important;
}
// .ant-form-item-explain {
//   position: absolute;
//   bottom: -28px;
// }
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0;
}
//--------- end ----------//
//--------- upload ----------//
.upload-dragger-img {
  width: 100%;
  max-height: 180px;
  object-fit: cover;
}
.ant-upload.ant-upload-drag {
  @extend .rounded;
  background: transparent;
  .ant-upload.ant-upload-btn {
    padding: 0;
  }
}
// .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
//   // border-color: $primary;
// }
.rounded-circle .ant-upload.ant-upload-select-picture-card {
  @extend .rounded-circle;
  @extend .avatar-upload;
}
//--------- end ----------//

//------------ Spin --------------//
.ant-spin-dot {
  width: 100px;
  height: 100px;
}
.ant-spin-dot i {
  width: 40px !important;
  height: 40px !important;
}

.ant-spin-nested-loading > div > .ant-spin {
  bottom: 0;
  right: 0;
  margin: auto;
}

//------------ End ---------------//

.ant-message {
  z-index: 9999;
}

.ant-radio-group.btn-radio-switch {
  width: 100%;
  text-align: center;
}
.button-tab-switch {
  background-color: $background_color !important;
  border-radius: 5px !important;
  width: 50%;
  border-color: $background_color !important;
  // padding: 7px 0px 33px 0px;
  border-radius: 8px !important;
}
.button-tab-switch:hover {
  color: #fff;
  background-color: #636f83 !important;
  border-color: $background_color !important;
}

label.button-tab-switch.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background: $primary !important;
  border-color: $primary;
  color: white;
  // border-radius: 25px 0px 0px 25px !important;
}
.ant-radio-button-wrapper.button-tab-switch:not(:first-child)::before {
  background-color: unset !important;
}
.button-tab-switch.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"])
  .ant-radio-button-wrapper:first-child {
  border-right-color: unset;
}
.button-tab-switch.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: unset;
}

.ant-input {
  background: $background_color !important;
  border-color: $background_color !important;
}
.ant-input :hover {
  background: $background_color !important;
  border-color: $background_color !important;
}

.ant-picker.custom-date {
  background: $background_color !important;
  border-radius: 5px;
  border: 0px !important;
}

.ant-picker-suffix {
  -ms-flex-item-align: center;
  align-self: center;
  margin-left: 4px;
  color: black !important;
  line-height: 1;
  pointer-events: none;
}

.form-booking.ant-form-item {
  margin-bottom: 4px !important;
}
.list-scroll-y .ant-list-split .ant-list-item {
  border-bottom: 0px solid #f0f0f0 !important;
}

.list-scroll-y .ant-list-item {
  padding: 0px 0px !important;
}

// .ant-list-item {
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-align: center;
//   align-items: center;
//   -ms-flex-pack: justify;
//   justify-content: space-between;
//   padding: 0px 0;
//   color: rgba(0, 0, 0, 0.65);
// }

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: white !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary !important;
  border-color: $primary !important;
}
.position-relative {
  position: relative !important;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.myCcard {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.ant-form-item-control-input-content {
  -ms-flex: auto;
  flex: auto;
  max-width: 100%;
  text-align: -webkit-center;
}

.upload-porter {
  margin-bottom: 30px;
}

.ant-input-password.ant-input-affix-wrapper.ant-input-affix-wrapper-sm {
  border-color: #f0eff7;
  border-radius: 0.5rem !important;
  background-color: $background_color;
}

.ant-select-selection-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-big-size .ant-select-selection-search {
  padding: 20px 5px 20px 5px !important;
}

.select-building .ant-select-selection-search {
  padding: 15px 5px 15px 5px !important;
}

.input-Search {
  height: 40px;
  border-radius: 0.25rem;
  background-color: $field-input-bg_color_2;
  color: black;
  padding: 0.5px 11px;
  font-size: 16px;
}

.ant-input-search:not(.ant-input-search-enter-button) {
  padding-right: 0;
  background: $field-input-bg_color_2;
}

.input-Search.ant-input-affix-wrapper > input.ant-input {
  background-color: $field-input-bg_color_2 !important;
}

.select-range .ant-select-selector {
  width: 170px !important;
  border-radius: 0.25rem !important;
  background: #f3f5f7 !important;
  border-color: #f3f5f7 !important;
  height: 40px !important;
}

.select-range.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 37px;
}

span.anticon.anticon-search.ant-input-search-icon {
  color: black !important;
  font-size: 18px;
  font-weight: 900;
  // padding: 15px;
}

.check-dashboard .ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
  text-align: unset;
  margin-left: 25px;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding: 15px !important;
  text-align: center;
  vertical-align: middle;
}