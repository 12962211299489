// $primary_color: #fff;
// $secondary_color: #ced2d8;
// $warning_color: #f9b115;
// $success_color: #2eb85c;
// $info_color: #3399ff;
// $danger_color: #e55353;
// $light_color: #ebedef;
$dark_color: #636f83;
$gray_color: #e4e4e475;
$gray_color_2: #f3f5f7;
// $background_primary: #fff;

$field-input-bg_color: #f0eff7a9;
$field-input-bg_color_2: #f4f4f8c9;
$border-line_color: #f0eff781;
// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$oldpink: #e39a9a;
$pink: #fd9f9f;
$red: #d62a2a;
$red-status-report: #fd9f9f;
$yellow-status-report: #ffbc6e;
$green-status-report: #84e0be;
$white-status-report: #ffff;
$black-btn-pdf: #000000a5;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #00bf78;
$teal: #84e0be;
$cyan: #6cb2eb;
$light: #e3eaf1;

// core ui
// ปรับเปลี่ยนสี primary ต้องการ
// ในกรณีที่เปลี่ยนพื้นหลังเป็นสีอ่อน แล้วมอง link ไม่ชัดให้เปลี่ยนค่า $navbar-sidebar-link-color ตามที่ต้องการ
// $primary: #f6993f;
$info: #9fd4fd;
$primary: #112637;
$secondary: #f0eff7a9;

$navbar-sidebar-link-color: $dark_color;
$c-header: #112637;
$p-secondary-bg: #0a1722;
$primary-bg: #f1f1f4;

// $primary-bg: #f3f9f7;
// navbar
$navbar-brand-bg: darken($primary, 10%) !default;
$navbar-bg: darken($primary, 10%) !default;
$navbar-color: $navbar-sidebar-link-color !default;
$navbar-active-color: $navbar-sidebar-link-color !default;
$navbar-hover-color: $navbar-sidebar-link-color !default;
$navbar-border: (
  bottom: (
    size: 1px,
    style: solid,
    color: $navbar-bg,
  ),
) !default;

// Sidebar
$sidebar-bg: darken($primary, 10%) !default;
$sidebar-nav-link-icon-color: $navbar-sidebar-link-color !default;
$sidebar-nav-link-color: $navbar-sidebar-link-color !default;
$sidebar-nav-title-color: $navbar-sidebar-link-color !default;

// form
// เพิ่มสีกรอบ input ให้เด่นขึ้น
$input-border-color: #cacfd4;
$input-group-addon-border-color: #cacfd4;
