// Here you can add other styles
@import "custom/swal";
@import "custom/card";
@import "custom/text";
@import "custom/login";
@import "custom/button";
@import "custom/sidebar";
@import "custom/reset_style";

.badge_report_status_complate {
  font-size: 100% !important;
  background-color: $green-status-report;
  color: $white-status-report;
}
.badge_report_status_toship {
  font-size: 100% !important;
  background-color: $yellow-status-report;
  color: $white-status-report;
}
.badge_report_status_cancel {
  font-size: 100% !important;
  background-color: $red-status-report;
  color: $white-status-report;
}

.btn_pdf {
  background-color: $gray_color_2;
  border: $border-line_color;
  border-radius: 0.5rem;
}

.opacity_5 {
  opacity: 0.5;
}
.show {
  display: block;
}
.d-non {
  display: none;
}

.building-header-title-bg {
  height: 15rem;
  color: #fff;
  background-size: cover, contain;
  background-color: $primary;
  background-image: url(/images/bg/bg-building.png);
  background-repeat: no-repeat;
  background-position: top;
}
.bg-gray {
  background-color: $gray_color;
}
.bg-green {
  height: 15rem;
  background-color: $primary;
}
.cursor-pointer {
  cursor: pointer !important;
}

.cover-img {
  display: flex;
  overflow: hidden;
  border: 3px solid $gray_color;
  justify-content: center;
}
.building-cover-img {
  width: 10rem;
  height: 10rem;
  @extend .cover-img;
}
.avatar-upload {
  width: 10rem;
  height: 10rem;
}
.avatar-cover-img {
  @extend .avatar-upload;
  @extend .cover-img;
  @extend .rounded-circle;
}

//puwaned css******************************
.font-14 {
  font-size: 14px !important;
}
.align-upload-card {
  display: block !important;
  margin: auto !important;
}
.search-fixed {
  z-index: 1000;
  right: 0rem;
  top: 4rem;
  background-color: $primary;
  padding: 10px;
  border-radius: 50% 0 0 50% !important;
}
.booking-search-fixed {
  z-index: 1000;
  right: 0rem;
  top: 4rem;
  background-color: #fd9f9f;
  border-radius: 50% 0 0 50% !important;
}
.booking-btn-submit {
  font-size: 14px;
  height: 50px;
  background-color: #84e0be;
  border-color: #00bf78;
  padding: 0px 80px;
  min-height: unset;
  min-width: unset;
  border-radius: 10px;
}
@font-face {
  font-family: Kanit;
  src: url("../assets/fonts/Kanit.ttf");
}
.floor-number-box {
  width: 40px;
  height: 40px;
  font-size: 1.2rem;
  background-color: white;
  @extend .d-flex,
    .justify-content-center,
    .align-items-center,
    .rounded-circle,
    .shadow-sm;
}
.floor-result-box {
  cursor: pointer;
}
.floor-result-box:hover {
  background-color: #84e0be !important;
  color: white;
}
.f-height-card {
  display: block;
  position: absolute;
  height: auto;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
}
.font-kanit {
  font-family: Kanit;
}

.dashboard-rightside-header {
  background-color: $primary;
  color: white;
  @extend .bordered-top;
}
.dashboard-rightside-date {
  padding: 3px;
  border: 1px solid rgba(112, 112, 112, 0.1);
  border-radius: 0.3rem;
  font-size: 0.7rem;
}
.dashboard-bottom-label {
  border-radius: 0.3rem;
  font-size: 1.5rem;
  width: 120px;
  margin: auto;
}
.table-no-item {
  font-size: 5rem;
  display: flex;
  justify-content: center;
  padding: 5rem;
}
.table th,
.table td {
  vertical-align: middle !important;
  border-color: white !important ;
  border: unset !important;
  text-align: center;
}

.action-box {
  padding: 3px;
  border-radius: 100%;
  display: flex;
  cursor: pointer;
  margin: 0px 3px;
  margin-top: 10px;
}
.action-delete {
  box-shadow: 0 0 0 0.4px #c91d17;
  border: 1px solid #c91d17;
  color: #c91d17;
}
.action-delete:hover,
.action-delete:active *,
.action-delete:focus {
  background-color: #c91d17 !important;
  box-shadow: 0 0 0 0.4px #c91d17 !important;
  border: 1px solid #c91d17 !important;
  border-color: #c91d17 !important;
  color: white !important;
}
.action-edit {
  border: 1px solid #52575d;
  box-shadow: 0 0 0 0.4px #52575d;
}
.action-edit:hover,
.action-edit:active *,
.action-edit:focus {
  background-color: #52575d !important;
  box-shadow: 0 0 0 0.4px #52575d !important;
  border: 1px solid #52575d !important;
  border-color: #52575d !important;
  color: white !important;
}
.action-icon {
  width: 20px !important;
  height: 20px !important;
  font-size: 1rem !important;
}
.bordered {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}

.bordered2 {
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
}
.custom-modal-header {
  background-color: #f1f1f7;
  @extend .bordered-top;
}
.bordered-top {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}
.cursor-pointer tr {
  cursor: pointer;
}
.gateway-count-box {
  border-color: #7070701f;
  background-color: #ffffff;
  min-width: unset;
  min-height: unset;
  padding: 9px 15px;
}
.gateway-count-box:hover {
  border-color: #f6f6f8;
  background-color: #f6f6f8;
}
.booking-height-component .ant-select-selector {
  height: 60px !important;
}
.booking-radio-group .ant-radio-button-wrapper-checked,
.ant-radio-button-wrapper-checked:before {
  background-color: #fd9f9f !important ;
  border-color: #fd9f9f !important ;
}
.user-role-radio-group .ant-radio-button-wrapper {
  padding: 0 40px;
  height: 50px;
  display: inline-flex;
  align-items: center;
}
.user-role-radio-group .ant-radio-button-wrapper-checked,
.ant-radio-button-wrapper-checked:before {
  background-color: #112637 !important ;
  border-color: #112637 !important ;
}
.user-role-radio-group > label:first-of-type {
  border-radius: 5px 0 0 5px;
}
.user-role-radio-group > label:last-of-type {
  border-radius: 0 5px 5px 0;
}
.booking-btn-complete {
  font-size: 10px;
  min-height: unset;
  min-width: unset;
  border-radius: 20px;
  background-color: #00bf787b;
  color: white !important;
  border-color: $green;
  padding: 3px 15px;
}

.booking-btn-pending {
  font-size: 15px;
  min-height: unset;
  min-width: unset;
  border-radius: 20px;
  background-color: #ff8b8f;
  color: white !important;
  border-color: #f9585e;
  padding: 3px 15px;
}
.booking-list-select .ant-select-selector {
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center;
}
.mark-h-60 .ant-select-selector {
  height: 60px !important;
  font-size: 1rem;
  display: flex;
  align-items: center;
}
.mark-h-40 .ant-select-selector {
  height: 40px !important;
  display: flex;
  align-items: center;
}
.status-card-active {
  border: 1px solid #00bf78;
  background-color: #84e0be;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
}
.status-card-inactive {
  border: 1px solid #e39a9a;
  background-color: #fd9f9f;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
}
.search-btn-form {
  min-width: unset;
  color: white;
  height: 40px;
}
//end of puwaned css **********************
.custom-selector-h {
  .ant-select-selector {
    height: 40px !important;
  }
}
.card-time {
  border: 1px solid #f0f2f8;
}

.size-image .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  width: 12rem !important;
  height: 12rem !important;
}

.password {
  background-color: #f0eff7;
  border-radius: 0.5rem !important;
  border: none;
}
.card-received-time {
  border: 1.5px solid #d1d1d1;
  border-radius: 5px;
  background-color: #f1f1f7;
  text-align: center;
  padding: 5px;
  color: $primary;
}
.card-return-time {
  border: 1.5px solid #d1d1d1;
  border-radius: 5px;
  background-color: #f1f1f7;
  text-align: center;
  padding: 5px;
  color: $pink;
}
.col-width-custom {
  flex: 0 0 22%;
  max-width: 25%;
}

.font-11 {
  font-size: 11px;
}

// .list-scroll-y {
// height: 360px;
// width: 100%;
// overflow-y: scroll;
// }

/* Let's get this party started */
::-webkit-scrollbar {
  width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #f5f6fa;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #f5f6fa;
}

.primary {
  background-color: $primary !important;
  color: white;
}

.w-15 {
  width: 15% !important;
}
.w-12 {
  width: 12% !important;
}
.wht-c {
  font-size: 18px !important;
  font-weight: 500 !important;
  background-color: white !important;
  padding: 0px 20px;
  height: 40px;
  font: 1vw sans-serif;
  color: #6b6b6b;
}
.wht-c:hover {
  color: black;
  background-color: #646566;
  border-color: rgb(185, 185, 185);
}
.wht-s {
  font-size: 18px !important;
  font-weight: 500 !important;
  padding: 0px 25px;
  background-color: #112637;
  color: white !important;
  height: 40px;
  font: 1vw sans-serif;
}
.wht-s:hover {
  color: white;
  background-color: #646566;
  border-color: rgb(185, 185, 185);
}

.bt-h {
  min-height: 2rem !important;
}

.checkbox-fix {
  min-height: 15px;
  min-width: 15px;
}

.checkbox-fix-dash {
  min-height: 15px;
  min-width: 15px;
  margin-bottom: -3px;
}
.box-item-dashboard {
  margin: 25px;
  // border-right: 5px solid #d8dbe0;
}

.p-mb-1 {
  margin-bottom: 10px !important;
}

.align-text-center .ant-form-item-label {
  text-align: center;
}

.sweet-c {
  background-color: #91c1d4 !important;
  color: white !important;
}
.border-radius-tab-first {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.border-radius-tab-second {
  border-radius: 0px !important;
}
.border-radius-tab-last {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

@media only screen and (max-width: 768px) {
  .ipad-layout {
    justify-content: center !important;
  }
  .w-bt {
    width: 100% !important;
  }
  .ml-2 {
    margin-left: 0px !important;
  }
}
.bd-radio {
  padding: 0px 50px;
}
.border-left-radio.ant-radio-button-wrapper {
  border-radius: 10px 0px 0px 10px;
}

.border-right-radio.ant-radio-button-wrapper {
  border-radius: 0px 10px 10px 0px;
}
.bd-radio.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: white;
  background: $primary;
  border-color: $primary;
}
.bd-radio.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: white !important;
}
.bd-radio.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background: $primary;
}
.bd-radio.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  border-right-color: $primary;
}
.bd-radio.ant-radio-button-wrapper:hover {
  position: relative;
  color: white;
  background: $primary;
  border-color: $primary;
}

.btn-detail.btn {
  min-width: 120px;
  min-height: 30px;
  width: auto;
  font: 1vw sans-serif;
}

@media only screen and (max-width: 768px) {
  .Btn-type-list-booking {
    padding-left: 1rem !important;
  }
  .font-sm-book {
    font-family: Kanit;
    font-size: 13px !important;
  }
  .font-sm-book2 {
    font-family: Kanit;
    font-size: 12px !important;
  }
  .bt-booking-inactive {
    background-color: #ffffff;
    color: #3b3434;
    font-size: 9px;
    padding: 5px;
    border-radius: 0.25rem;
    min-width: 50px !important;
  }
  .bt-booking-active {
    background-color: #112637;
    color: white;
    font-size: 9px;
    padding: 5px;
    border-radius: 0.25rem;
    min-width: 30px !important;
  }
}

.Btn-type-list-booking {
  padding-left: 3rem;
}

.font-btn-book {
  font-family: Kanit;
  font-size: 15px;
}

.font-sm-book {
  font-family: Kanit;
  font-size: 14px;
}
.font-sm-book2 {
  font-family: Kanit;
  font-size: 14px;
}

.sticky-col {
  position: sticky;
  top: 80px;
}

.login-field-bp {
  background: $field-input-bg_color;
  border-color: $field-input-bg_color;
  border-radius: 5px;
  height: 50px;
}

.btn-login {
  border-radius: 10px;
  padding: 0px 50px;
}

.img-bg-login {
  width: 100%;
  height: 100vh !important;
}

.bg-login {
  background-color: white;
}
.w-70 {
  width: 70% !important;
}
.mb-10 {
  margin-bottom: 10rem !important;
}

// button.shadow-sm.search-btn-porter.btn.btn-info {
//   margin-left: -125px;
// }

@media only screen and (max-width: 1024px) {
  .btn_pdf {
    font-size: 14px !important;
    color: $black-btn-pdf !important;
  }
  button.shadow-sm.search-btn-porter.btn.btn-info {
    margin-left: unset !important;
  }
}

// @media only screen and (max-width: 2560px) {
//   .img-bg-login {
//     height: 1080px !important;
//   }
// }

// @media only screen and (max-width: 1920px) {
//   .img-bg-login {
//     height: 1080px !important
//   }
// }

// @media only screen and (max-width: 1440px) {
//   .img-bg-login {
//     height: 1080px !important;
//   }
// }

@media only screen and (max-width: 760px) {
  .login-display {
    display: none !important;
  }
  .login-display-mobile {
    margin-top: 120px !important;
    display: block !important;
  }
}
.login-display {
  display: block;
}
.login-display-mobile {
  display: none;
}
.table-responsive {
  border-radius: 10px;
}
.Table-porter.table {
  width: 100%;
  color: #4f5d73;
  // border-right: 0px solid $field-input-bg_color !important;
  // border-left: 0px solid $field-input-bg_color !important;
  border-right: 4px solid white !important;
  border-left: 4px solid white !important;
  overflow: hidden !important;
}

table.table.Table-porter.mb-0 thead {
  border-radius: 60px !important;
  border-right: 5px solid $field-input-bg_color_2 !important;
  border-left: 5px solid $field-input-bg_color_2 !important;
}

.Table-porter.table thead tr {
  background: $field-input-bg_color;
  border-radius: 25px;
}
td.d-flex.align-items-center.justify-content-center {
  border: 0px !important;
}
.Table-porter.table tbody td {
  border-right: 3px solid $field-input-bg_color !important;
}

.Table-porter.table tbody td.item-return {
  border-right: 0px !important;
}

// .card-login.card {
//   // position: relative;
//   display: flex;
//   flex-direction: column;
//   min-width: 0;
//   margin-top: 2.3rem;
//   margin-bottom: 0rem;
//   word-wrap: break-word;
//   background-clip: border-box;
//   border: none;
//   border-radius: 0.25rem;
//   background-color: #fff;
//   border-color: #d8dbe0;
// }

.custom-addItem-header {
  padding-top: 8px;
}

.status-color-active {
  background-color: $teal;
  border-radius: 5px;
  border: 1px solid $green;
  padding: 5px 22px;
  color: white;
}
.status-color-inactive {
  background-color: #f9585e;
  border-radius: 5px;
  border: 1px solid $oldpink;
  padding: 5px 15px;
  color: white;
}

.status-color-pending {
  background-color: #ff8b8f;
  border-radius: 5px;
  border: 1px solid #f9585e;
  padding: 5px 15px;
  color: white;
}

.t-sidebar-nav-item .img-icon {
  webkit-filter: contrast(120%) brightness(250%) opacity(100%);
  filter: contrast(120%) brightness(250%) opacity(100%);
  width: 25px;
  margin-right: 5px;
}

.bt-booking-active {
  background-color: #112637;
  color: white;
  border-radius: 0.25rem;
}

.bt-booking-inactive {
  background-color: #ffffff;
  color: #3b3434;
  border-radius: 0.25rem;
}

.img-profile {
  border-radius: 50%;
}

.qr-block {
  background-color: #f4f7fc;
  height: 100%;
  border-radius: 0.25rem !important;
}

.bt-cancle {
  background-color: #ed4c4c !important;
  color: white !important;
  border-radius: 0.25rem;
}

.bt-cancled {
  background-color: #ed4c4c !important;
  color: white !important;
  border-radius: 0.25rem;
}
.bt-retry {
  background-color: $primary !important;
  color: white !important;
  border-radius: 0.25rem;
}

.bt-qr-origin {
  background-color: #9fd4fd !important;
  color: white !important;
  border-radius: 0.25rem;
}

.block-origin {
  background-color: #9fd4fd;
  color: white;
  border-radius: 5px;
  padding: 4px 10px 4px 10px;
  width: 4rem;
  text-align: center;
  border-radius: 1rem !important;
}
.block-dest {
  background-color: #84e0be;
  color: white;
  border-radius: 5px;
  padding: 4px 10px 4px 10px;
  width: 4rem;
  text-align: center;
}

.bt-qr-dest {
  background-color: #84e0be;
  color: white;
  border-radius: 0.25rem;
}

.bt-qr-retry {
  background-color: $primary;
  color: white;
}

.size-bt-table.btn {
  min-width: 60px;
  min-height: auto;
}

.scorling {
  max-height: 600px;
  width: auto;
  overflow-y: auto;
}

.size-qrcode {
  height: auto !important;
  max-width: 72px !important;
}

.pt-45 {
  padding-top: 45px;
}

.p-plus {
  padding: 0px 20px 0px 20px !important;
}

.mh-auto {
  min-width: auto !important;
}
.w-30 {
  width: 30% !important;
}

.f-list {
  font-weight: bold;
  font-size: 12px;
}

.moniter.Table-porter.table thead tr {
  background: unset !important;
  border-radius: 25px;
  border-bottom: 4px solid $field-input-bg_color;
}

.moniter.Table-porter.table tbody tr {
  border-right: 0px !important;
  border-top: 4px solid $field-input-bg_color !important;
}

.moniter.Table-porter.table tbody td {
  border-right: 0px solid $field-input-bg_color !important;
}

table.table.moniter.Table-porter.mb-0 thead {
  border-radius: 60px !important;
  border-right: 0px solid $field-input-bg_color_2 !important;
  border-left: 0px solid $field-input-bg_color_2 !important;
}

.round {
  border-radius: 0.25rem;
}

.scroll-able {
  overflow-y: auto;
  overflow-x: hidden;
  height: 300px;
}
button.MuiButtonBase-root.MuiIconButton-root {
  font-size: 30px;
  padding: 3px;
}

.border-round {
  border: 0.25em solid #84e0be;
  padding: 3px;
}

.table-no-item {
  font-size: 2rem;
}

.wrapper {
  width: 500px;
  margin: 0 auto;
}

.pinker {
  background-color: rgb(253, 159, 159) !important;
}

.profile.dropdown-item {
  margin-top: 7px;
  /* text-align: -webkit-center; */
}
.c-avatar-img {
  width: 100%;
  height: 35px !important;
  border-radius: 50em;
}

.qr-btn {
  min-width: 85px;
  min-height: 31px;
  background-color: "#9FD4FD" !important;
  color: "white" !important;
  font-size: 14px;
}

.ali-center {
  display: inline-flex;
  align-items: center;
}

.btn {
  font-size: unset;
}

.oc-1 {
  opacity: 0.1 !important;
}

.status-color-done {
  background-color: #84e0be;
  border-radius: 5px;
  border: 1px solid #00bf78;
  padding: 5px 22px;
  color: white;
}

.status-color-unavailble {
  background-color: #ffa500;
  border-radius: 5px;
  border: 1px solid $oldpink;
  padding: 5px 15px;
  color: white;
}

.status-color-cancel {
  background-color: #e55353;
  border-radius: 5px;
  border: 1px solid #ba3f3f;
  padding: 5px 15px;
  color: white;
}

.status-color-working {
  background-color: #639dda;
  border-radius: 5px;
  border: 1px solid #2069b5;
  padding: 5px 15px;
  color: white;
}

.status-color-accept {
  background-color: #ff8b8f;
  border-radius: 5px;
  border: 1px solid #f9585e;
  padding: 5px 15px;
  color: white;
}

.no-border {
  border-collapse: unset !important;
  border-radius: 10px;
}

.grouptable {
  border-radius: 10px;
  border: 1px solid black !important;
}
.grouptable {
  th,
  tr {
    padding: 6px;
    background-color: $secondary;
  }
}

.upload-style {
  span {
    width: 100%;
  }
  .ant-upload {
    width: 100%;
  }
}

.text-cut {
  white-space: nowrap;
  width: 95px;

  overflow: hidden;
  text-overflow: ellipsis;
}

.rotate {
  height: 100px;
  white-space: nowrap;
  display: flex;
  align-items: flex-end;
}
.rotate > div {
  transform: rotate(270deg);
  width: 20px;
}

.border-table {
  border-radius: 0px 0px 8px 0px;
}
