.bg-color {
  background: #20664f;
}
.inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
}
.inner-bg {
  position: absolute;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  margin-left: -90%;
}

.bg-layers1 {
  height: 160vh;
  width: 160vh;
  background: #0e5a40;

  @media only screen and (max-width: 768px) {
    height: 240vh;
    width: 240vh;
  }
}
.bg-layers2 {
  height: 140vh;
  width: 140vh;
  background: #06543a;

  @media only screen and (max-width: 768px) {
    height: 220vh;
    width: 220vh;
  }
}
.bg-layers3 {
  height: 120vh;
  width: 120vh;
  background: #ffffff;
  @media only screen and (max-width: 768px) {
    height: 200vh;
    width: 200vh;
  }
}
