// Here you can add other styles

//--------------------- clear style ----------------------//
a:hover {
  text-decoration: none;
}
ul {
  list-style-type: none !important;
}
a {
  display: inline-block;
}
button:focus {
  outline: none;
  outline: none;
}
//---------------------- end --------------------------//
