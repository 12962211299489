// Here you can add other styles
//--------------------  card coreUI custom ---------------//
.rounded {
  border-radius: 1rem !important;
}
.card {
  // @extend .shadow-sm;
  border: none;
  padding: 1rem;
  @extend .rounded;
}
.card-header {
  padding: 1rem;
  border-bottom: none;
}
.card-body {
  padding: 0;
}
.card-upload {
  @extend .rounded;
  padding: 0;
  border: 2px dotted #636f8333;
  background: $primary-bg;
}
.action-card.card-footer {
  border: 0;
  border-radius: 0 0 1rem 1rem;
  .btn {
    height: 4rem;
  }
  .btn-primary {
    border-radius: 0 0 0 1rem;
  }
  .btn-gray {
    border-radius: 0 0 1rem 0;
  }
  .btn-gray:hover {
    background-color: $red;
    color: #fff;
  }
}

//----------------------- end -----------------------------//
