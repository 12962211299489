// Here you can add other styles

// header coreUI custom
.c-header {
  border-bottom: none !important;
  // background: $c-header;
}
.c-header .c-header-toggler-icon {
  // filter: contrast(0) brightness(50) opacity(100%);
}

// sidebar coreUI custom
.c-sidebar {
  // white-space: nowrap;
  background: #fff !important;
  color: #000 !important;
}

// logo sidebar
.c-sidebar .c-sidebar-brand {
  color: #000 !important;
  background: none !important;
}

.c-sidebar .c-sidebar-nav-link {
  color: $dark_color !important;
}
.c-sidebar .c-sidebar-nav-link.c-active:hover {
  color: #fff !important;
}

//---------------- end ---------------------------//

//------------- sidebar my custom -------------------//
.c-sidebar .c-sidebar-nav-dropdown-toggle::after,
.c-sidebar
  .c-sidebar-nav-dropdown.active.c-show
  .c-sidebar-nav-dropdown-toggle::after {
  filter: contrast(20%) brightness(50%) opacity(90%);
}
.c-sidebar .c-sidebar-nav-dropdown.active .c-sidebar-nav-dropdown-toggle::after,
.c-sidebar
  .c-sidebar-nav-dropdown.active.c-show
  .c-sidebar-nav-dropdown-toggle:hover::after,
.c-sidebar
  .c-sidebar-nav-dropdown.active.c-show
  .c-sidebar-nav-dropdown-toggle::after,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover::after {
  filter: none;
}
html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-link,
html:not([dir="rtl"])
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle {
  padding-left: 25px;
}

//----------------------------------------------------//
.img-icon {
  filter: contrast(120%) brightness(250%) opacity(100%);
}

//เปลี่ยนสีพื้นหลังตอน active
.c-sidebar-nav-item.active,
.c-sidebar-nav-dropdown.active,
.c-sidebar-nav-dropdown.active.c-show .c-sidebar-nav-dropdown-toggle,
.c-sidebar-nav-item.t-sidebar.t-sidebar-nav-item.active {
  background: $primary;
}
.c-sidebar-nav-dropdown.active.c-show .c-sidebar-nav-dropdown-toggle {
  background: $p-secondary-bg;
}

//เปลี่ยนสีรูปภาพไอคอนและตัวหนังสือตอน active
//เปลี่ยนสีรูปภาพไอคอนและตัวหนังสือตอน hover
.c-sidebar-nav-item.active .c-sidebar-nav-link,
.c-sidebar-nav-item:hover .c-sidebar-nav-link,
.c-sidebar-nav-dropdown.active .c-sidebar-nav-dropdown-toggle,
.c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar-nav-dropdown.active.c-show .c-sidebar-nav-dropdown-toggle,
.c-sidebar-nav-dropdown.active.c-show .c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar-nav-dropdown-toggle:hover {
  color: #fff !important;
}
.c-sidebar-nav-item.active .img-icon,
.c-sidebar-nav-item:hover .img-icon,
.c-sidebar-nav-dropdown-toggle:hover .img-icon {
  filter: contrast(100%) brightness(350%) opacity(90%);
}

.c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: $primary-bg;
}
.c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  color: $dark_color !important;
}
.c-sidebar .c-sidebar-minimizer {
  background-color: rgba(0, 0, 21, 0.103);
}
//----------------------- end -----------------------------//
//----------------------- Mini Sidebar  -------------------//
.c-sidebar-minimized {
  .c-sidebar-nav-item.active,
  .c-sidebar-nav-dropdown.active,
  .c-sidebar-nav-dropdown.active.c-show {
    background: transparent;
    background-image: url("/images/bg/bg-item-active.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain, cover;
  }
  .c-sidebar-nav-dropdown-toggle {
    background: transparent !important;
  }
  .c-sidebar-nav-dropdown:hover {
    .c-sidebar-nav-link {
      color: #fff !important;
    }
    .img-icon {
      filter: contrast(100%) brightness(350%) opacity(90%);
    }
  }
  .c-sidebar-nav-dropdown {
    transition: none;
  }
}
//----------------------- end -----------------------------//
//------------------------fix by sol-----------------------//
.c-sidebar .c-sidebar-nav-dropdown.c-show {
  /* background: #f1f1f4; */
  background: white;
}

@media (min-width: 768px){
.c-sidebar-minimized {
  .c-sidebar-nav-item.active,
  .c-sidebar-nav-dropdown.active,
  .c-sidebar-nav-dropdown.active.c-show {
    background: #112637;
    background-image: none;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain, cover;
  }
  li.c-sidebar-nav-item.t-sidebar.t-sidebar-nav-item a.c-sidebar-nav-link{
    color: white !important;
  }

}
}