.leaflet-container {
  width: 100%;
  height: 50vh;
  background: #fff;
}

.l-icon {
  background-color: transparent;
  border: none;
}

